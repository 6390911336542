// Page definitions

export type Page = {
  name: string,
  href: string,
  title: string,
  documentTitle: string,
  description: string,
  children: Page,
  showInMainNav: boolean,
  srcImport: Promise<any>,
  showHeader: boolean
}

interface Pages {
  [key: string]: Page
}

export const Pages = {
  home: {
    name: 'Home',
    href: '/',
    title: 'Home',
    documentTitle: 'The Last Reef',
    description: 'The Last Reef - A NeuroAnimation Experience',
    showInMainNav: false,
    srcImport: () => import('./home/index.js'),
    showHeader: true
  },
  privacy: {
    name: 'Privacy',
    href: '/privacy',
    title: 'Privacy',
    documentTitle: 'NeuroAnimation | Privacy Policy',
    description: 'NeuroAnimation\'s privacy and data policy.',
    showInMainNav: false,
    srcImport: () => import('./privacy/index.js'),
    showHeader: false
  },
};


export default Pages;
